import React from "react";
import styled from "styled-components";

const BlobsStyled = styled.section`
  --blob-1-color: #f10e0e60;
  --blob-2-color: #0d3ace60;
  --blob-3-color: #f7f7f760;
  position: relative;
  width: 100%;
  height: 100%;

  .blob-1,
  .blob-2,
  .blob-3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(40px);
    will-change: transform;

    animation-timing-function: ease;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  .blob-1 {
    border-radius: 30% 50% 45% 60%;
    background: var(--blob-1-color);
    animation-name: blob-1-animation;
    animation-duration: 15s;
  }

  .blob-2 {
    border-radius: 50% 30% 65% 40%;
    background: var(--blob-2-color);
    animation-name: blob-2-animation;
    animation-duration: 14.5s;
  }

  .blob-3 {
    border-radius: 60% 40% 50% 30%;
    background: var(--blob-3-color);
    animation-name: blob-3-animation;
    animation-duration: 14s;
  }

  @keyframes blob-1-animation {
    0% {
      transform: rotate(1turn);
    }
    50% {
      transform: rotate(0turn);
    }
    100% {
      transform: rotate(1turn);
    }
  }

  @keyframes blob-2-animation {
    0% {
      transform: rotate(-1turn);
    }
    50% {
      opacity: 0.5;
      transform: rotate(0turn);
    }
    100% {
      opacity: 1;
      transform: rotate(-1turn);
    }
  }

  @keyframes blob-3-animation {
    0% {
      transform: rotate(1turn);
    }
    50% {
      opacity: 0.25;
      transform: rotate(0turn);
    }
    100% {
      opacity: 1;
      transform: rotate(1turn);
    }
  }
`;

export const Blobs = () => {
  return (
    <BlobsStyled>
      <div className="moving-blobs">
        <div className="blob-1" />
        <div className="blob-2" />
        <div className="blob-3" />
      </div>
    </BlobsStyled>
  );
};
