import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { MainTemplate } from "../templates/mainTemplate";
import { Blobs } from "../components/Blobs";
import { Seo } from "../components/Seo";

const IndexPageStyles = styled.div`
  .hero {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    margin: 6vw 0 3rem 0;
  }
  .hero h1 {
    position: relative;
    grid-column: 1 / span 2;
    grid-row: 1 / span 4;
    font-size: clamp(4rem, 8vw, 8rem);
    line-height: 1;
    z-index: 105;
  }

  .blobs {
    height: 400px;
    position: relative;
    grid-column: 3 / span 3;
    grid-row: 1 / span 4;
  }

  .hero-nobel {
    position: absolute;
    z-index: 103;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 30vw;
    min-width: 200px;
    min-height: 200px;
    max-width: 400px;
    max-height: 400px;
  }

  h2 {
    font-size: clamp(3rem, 6vw, 6rem);
  }

  .nobels {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  p {
    position: relative;
    font-size: clamp(1.5rem, 3vw, 3rem);
    z-index: 105;
  }
  span {
    font-weight: bold;
  }

  a {
    text-decoration: underline;
    text-decoration-thickness: 0.04em;
    text-underline-offset: 0.03em;

    &:hover {
      color: var(--highlight-dark-theme);
    }
  }
`;

// markup
const IndexPage = () => {
  return (
    <>
      <Seo title="Web Devepoler's Nobel" />
      <MainTemplate>
        <IndexPageStyles>
          <section className="hero">
            <h1>{`Web Developer's Nobel`}</h1>
            <div className="blobs">
              <Blobs />
              <img
                className="hero-nobel"
                src="https://res.cloudinary.com/tihos/image/upload/c_scale,f_auto,w_400,h_400,q_auto/v1638510787/WDN/nobel.png"
                alt="Nobel"
                width="400"
                height="400"
              />
            </div>
          </section>
          <section className="content">
            <p>
              Welcome to the inaugural <span>Web Developer&apos;s Nobel</span>.
              Each year, starting in 2022, the{" "}
              <Link to="/wdn-committee">
                Web Developer&apos;s Nobel committee
              </Link>{" "}
              will choose the best web developers in the world in each of the
              prominent web development fields.
            </p>
          </section>
        </IndexPageStyles>
      </MainTemplate>
    </>
  );
};

export default IndexPage;
